<template>
    <auth-wrapper class="font-app">
        <div class="text-center mb-5">
            <router-link to="/">
                <img
                    class="img-fluid w-40 pb-3"
                    alt="logo"
                    src="@/assets/logo.png"
                />
            </router-link>
        </div>

        <error-card :errors="errors" />

        <h2 class="text-center mb-6">Enter your new password</h2>
        <validation-observer ref="validator">
            <password-input v-model="password" strict />
        </validation-observer>
        <div class="d-flex justify-content-end mt-7">
            <btn :loading="loading" @click="resetPassword" app block>
                Next
            </btn>
        </div>
    </auth-wrapper>
</template>

<script>
import passwordInput from '@/components/forms/password-input';

import authWrapper from './auth-wrapper';

export default {
    data() {
        return {
            password: '',
            errors: [],
            loading: false,
        };
    },
    methods: {
        async resetPassword() {
            this.errors = [];
            await this.$refs.validator.validate();
            if (!this.$refs.validator.flags.valid) {
                return;
            }

            this.loading = true;

            var payload = {
                password: this.password,
                token: this.$route.params.token,
                uid: this.$route.params.uid,
            };

            try {
                var resp = await this.$store.dispatch('resetPassword', payload);
                this.$store.commit('updateTokens', resp.data);
                this.$router.push({ name: 'Dashboard' });
            } catch (e) {
                this.errors.push(e.response.data);

                console.error(e.response);
            }

            this.loading = false;
        },
    },
    components: {
        passwordInput,
        authWrapper,
    },
};
</script>
